import React from 'react'
import useSWR from 'swr'
import { useAuth } from '../../../providers/authentication/context'
import useService from '../../../services/hooks/useService'
import MessagesService from '../../../services/MessagesService'

export default function UnreadMessagesIndicator() {
  const { user } = useAuth()
  const messagesService = useService(MessagesService)
  const { data: channels } = useSWR(
    [user.UUID, '/messages/channels?ParticipantUUID'],
    messagesService.findAllChannelsByParticipantUUID,
    { refreshInterval: 10000 }
  )

  const unreadChannels = channels?.filter((channel) => {
    const lastMessage = channel.Included?.LastMessage
    return lastMessage ? !lastMessage?.SeenBy?.[user.UUID] : false
  })

  const count = unreadChannels?.length || 0

  return count ? (
    <div className="rounded-full overflow-hidden-z border-2 border-white">
      <div className="flex items-center justify-center leading-none w-4 h-4 rounded-full bg-red-500 transform scale-90">
        <span className="text-white text-xs">{count > 9 ? '+9' : count}</span>
      </div>
    </div>
  ) : null
}
