import React from 'react'

export default function Magnifier({ size, ...rest }) {
  return (
    <svg width={size} height={size} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M2.49181 14.5425C-0.830498 11.2201 -0.830431 5.81415 2.49181 2.49178C5.81418 -0.830593 11.22 -0.830593 14.5424 2.49178C17.3609 5.31036 17.7878 9.50231 15.8241 12.7758C15.8241 12.7758 15.6831 13.0124 15.8736 13.2028C16.9604 14.2895 20.221 17.5502 20.221 17.5502C21.0863 18.4154 21.2923 19.6254 20.5249 20.3929L20.3928 20.5248C19.6255 21.2923 18.4155 21.0864 17.5502 20.2211C17.5502 20.2211 14.2965 16.9673 13.2119 15.8828C13.0123 15.6832 12.7757 15.8242 12.7757 15.8242C9.50234 17.7878 5.31039 17.361 2.49181 14.5425ZM12.9692 12.9693C15.4241 10.5144 15.424 6.52002 12.9691 4.06512C10.5143 1.6103 6.51991 1.61023 4.06508 4.06512C1.61019 6.51995 1.61019 10.5144 4.06508 12.9693C6.51998 15.424 10.5143 15.424 12.9692 12.9693Z"
        fill="currentColor"
      />
      <path
        d="M12.4273 8.0114C12.5416 8.0114 12.6577 7.98894 12.7697 7.94166C13.2169 7.75237 13.4262 7.23633 13.2369 6.78903C12.0472 3.97755 8.79206 2.65817 5.98065 3.84787C5.53342 4.03715 5.32418 4.55319 5.51346 5.00049C5.70282 5.4478 6.21872 5.6569 6.66616 5.46769C8.58431 4.65602 10.8054 5.55624 11.617 7.4744C11.759 7.80987 12.0847 8.0114 12.4273 8.0114Z"
        fill="currentColor"
      />
    </svg>
  )
}
