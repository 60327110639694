import React from 'react'

export default function Bell2({ size, ...rest }) {
  return (
    <svg width={size} height={size} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M20.1761 16.2869L18.947 14.5188C18.7792 14.2784 18.6889 13.9925 18.6882 13.6994V8.625C18.6882 6.71876 17.931 4.89059 16.5831 3.54267C15.2352 2.19475 13.407 1.4375 11.5007 1.4375C9.59451 1.4375 7.76634 2.19475 6.41842 3.54267C5.0705 4.89059 4.31325 6.71876 4.31325 8.625V13.6994C4.31256 13.9925 4.22227 14.2784 4.0545 14.5188L2.82544 16.2869C2.63934 16.4931 2.51696 16.7488 2.47311 17.023C2.42925 17.2973 2.4658 17.5784 2.57833 17.8324C2.69085 18.0863 2.87454 18.3022 3.10718 18.454C3.33981 18.6057 3.61143 18.6868 3.88919 18.6875H7.97888C8.14384 19.4999 8.58459 20.2303 9.22644 20.7549C9.86829 21.2795 10.6718 21.5661 11.5007 21.5661C12.3297 21.5661 13.1332 21.2795 13.7751 20.7549C14.4169 20.2303 14.8577 19.4999 15.0226 18.6875H19.1123C19.3901 18.6868 19.6617 18.6057 19.8943 18.454C20.127 18.3022 20.3106 18.0863 20.4232 17.8324C20.5357 17.5784 20.5722 17.2973 20.5284 17.023C20.4845 16.7488 20.3622 16.4931 20.1761 16.2869ZM11.5007 20.125C11.0559 20.1237 10.6223 19.9849 10.2594 19.7276C9.89655 19.4702 9.62216 19.1069 9.47388 18.6875H13.5276C13.3793 19.1069 13.1049 19.4702 12.7421 19.7276C12.3792 19.9849 11.9456 20.1237 11.5007 20.125ZM3.88919 17.25C3.92167 17.2199 3.95061 17.1861 3.97544 17.1494L5.23325 15.3381C5.56879 14.8574 5.74936 14.2856 5.75075 13.6994V8.625C5.75075 7.10001 6.35655 5.63747 7.43489 4.55914C8.51322 3.4808 9.97576 2.875 11.5007 2.875C13.0257 2.875 14.4883 3.4808 15.5666 4.55914C16.6449 5.63747 17.2507 7.10001 17.2507 8.625V13.6994C17.2521 14.2856 17.4327 14.8574 17.7682 15.3381L19.0261 17.1494C19.0509 17.1861 19.0798 17.2199 19.1123 17.25H3.88919Z"
        fill="currentColor"
      />
    </svg>
  )
}
