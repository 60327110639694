import React from 'react'

export default function Calendar({ size, ...rest }) {
  return (
    <svg width={size} height={size} viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M20.0228 0.353516H17.9934V2.38293C17.9934 2.78881 17.6552 3.0594 17.317 3.0594C16.9787 3.0594 16.6405 2.78881 16.6405 2.38293V0.353516H5.81696V2.38293C5.81696 2.78881 5.47872 3.0594 5.14049 3.0594C4.80225 3.0594 4.46402 2.78881 4.46402 2.38293V0.353516H2.4346C1.4199 0.353516 0.675781 1.23293 0.675781 2.38293V4.81822H22.3228V2.38293C22.3228 1.23293 21.1052 0.353516 20.0228 0.353516ZM0.675781 6.23881V18.6182C0.675781 19.8359 1.4199 20.6476 2.50225 20.6476H20.0905C21.1728 20.6476 22.3905 19.7682 22.3905 18.6182V6.23881H0.675781ZM6.69637 17.6035H5.07284C4.80225 17.6035 4.53166 17.4006 4.53166 17.0623V15.3712C4.53166 15.1006 4.7346 14.83 5.07284 14.83H6.76402C7.0346 14.83 7.30519 15.0329 7.30519 15.3712V17.0623C7.23755 17.4006 7.0346 17.6035 6.69637 17.6035ZM6.69637 11.5153H5.07284C4.80225 11.5153 4.53166 11.3123 4.53166 10.9741V9.28293C4.53166 9.01234 4.7346 8.74175 5.07284 8.74175H6.76402C7.0346 8.74175 7.30519 8.94469 7.30519 9.28293V10.9741C7.23755 11.3123 7.0346 11.5153 6.69637 11.5153ZM12.1081 17.6035H10.417C10.1464 17.6035 9.87578 17.4006 9.87578 17.0623V15.3712C9.87578 15.1006 10.0787 14.83 10.417 14.83H12.1081C12.3787 14.83 12.6493 15.0329 12.6493 15.3712V17.0623C12.6493 17.4006 12.4464 17.6035 12.1081 17.6035ZM12.1081 11.5153H10.417C10.1464 11.5153 9.87578 11.3123 9.87578 10.9741V9.28293C9.87578 9.01234 10.0787 8.74175 10.417 8.74175H12.1081C12.3787 8.74175 12.6493 8.94469 12.6493 9.28293V10.9741C12.6493 11.3123 12.4464 11.5153 12.1081 11.5153ZM17.5199 17.6035H15.8287C15.5581 17.6035 15.2875 17.4006 15.2875 17.0623V15.3712C15.2875 15.1006 15.4905 14.83 15.8287 14.83H17.5199C17.7905 14.83 18.0611 15.0329 18.0611 15.3712V17.0623C18.0611 17.4006 17.8581 17.6035 17.5199 17.6035ZM17.5199 11.5153H15.8287C15.5581 11.5153 15.2875 11.3123 15.2875 10.9741V9.28293C15.2875 9.01234 15.4905 8.74175 15.8287 8.74175H17.5199C17.7905 8.74175 18.0611 8.94469 18.0611 9.28293V10.9741C18.0611 11.3123 17.8581 11.5153 17.5199 11.5153Z"
        fill="currentColor"
      />
    </svg>
  )
}
