import React from 'react'
import SearchResults from '../../../shared/SearchResults'
import SearchBar from '../../SearchBar'
import CompaniesService from '../../../../services/CompaniesService'
import { t } from '@lingui/macro'
import AuthenticationService from '../../../../services/AuthenticationService'
import PostsService from '../../../../services/PostsService'
import GroupsService from '../../../../services/GroupsService'
import SearchResultItemCompany from '../../../shared/SearchResultItemCompany'
import SearchResultItemUser from '../../../shared/SearchResultItemUser'
import SearchResultItemPost from '../../../shared/SearchResultItemPost'
import SearchResultItemProduct from '../../../shared/SearchResultItemProduct'
import SearchResultItemGroup from '../../../shared/SearchResultItemGroup'

export default function Search() {
  return (
    <div className="">
      <SearchBar>{({ query }) => <SearchResults query={query} sources={searchSources} />}</SearchBar>
    </div>
  )
}

const searchSources = [
  {
    key: 'companies',
    name: () => t`Empresas`,
    service: CompaniesService,
    action: 'searchCompaniesByQuery',
    renderItem: function Item(item) {
      return <SearchResultItemCompany company={item} />
    },
  },
  {
    key: 'users',
    name: () => t`Usuarios`,
    service: AuthenticationService,
    action: 'searchUsersByQuery',
    renderItem: function Item(item) {
      return <SearchResultItemUser user={item} />
    },
  },
  {
    key: 'posts',
    name: () => t`Publicaciones`,
    service: PostsService,
    action: 'searchPostsByQuery',
    renderItem: function Item(item) {
      return <SearchResultItemPost post={item} />
    },
  },
  {
    key: 'groups',
    name: () => t`Grupos`,
    service: GroupsService,
    action: 'searchGroupsByQuery',
    renderItem: function Item(item) {
      return <SearchResultItemGroup group={item} />
    },
  },
  {
    key: 'products',
    name: () => t`Productos`,
    service: PostsService,
    action: 'searchProductPostsByQuery',
    renderItem: function Item(item) {
      return <SearchResultItemProduct post={item} />
    },
  },
]
