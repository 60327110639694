import React, { useEffect, useState } from 'react'
import Modal from '../../Modal'
import Card from '../../Card'
import Button from '../../Button'
import { Trans } from '@lingui/macro'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useLayout } from '../context'

export default function RequireAccessModal() {
  const { forceRequireAccess } = useLayout()
  const { asPath } = useRouter()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setOpen(true)
    }, 7000)
  }, [])

  // useEffect(() => {
  //   if (forceRequireAccess) {
  //     setOpen(true)
  //   }
  // }, [forceRequireAccess])

  return (
    <Modal
      animate
      open={open}
      maxWidth={550}
      showCloseButton
      onClose={() => setOpen(false)}
      closeOnOutsideClick={false}
    >
      <Card className="py-5 sm:px-10 py-5 sm:py-10">
        <Card.Body>
          <h1 className="font-bold text-xl leading-tight text-gray-700 mb-2">
            <Trans>Para continuar necesitas iniciar sesión</Trans>
          </h1>
          <h1 className="leading-tight  mb-10 text-gray-700">
            <Trans>Presiona el botón a continuación para iniciar sesión o crear una cuenta.</Trans>
          </h1>
          <Link href={`/authentication/access?return=${encodeURIComponent(asPath)}`} passHref>
            <Button asComponent="a" className="w-full block text-center cursor-pointer">
              <Trans>Acceder</Trans>
            </Button>
          </Link>
        </Card.Body>
      </Card>
    </Modal>
  )
}
