import React from 'react'

export default function Dots({ size, ...rest }) {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <circle cx="3" cy="10" r="3" fill="currentColor" />
      <circle cx="10" cy="10" r="3" fill="currentColor" />
      <circle cx="17" cy="10" r="3" fill="currentColor" />
    </svg>
  )
}
