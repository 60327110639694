import React from 'react'
import Link from 'next/link'
import { Trans } from '@lingui/macro'
import OutlinedButton from '../../../OutlinedButton'

export default function AccountOptions() {
  return (
    <Link href="/authentication/access">
      <OutlinedButton className="p-2 rounded-md hover:bg-gray-200 hover:bg-opacity-50">
        <span className="text-gray-700 text-xs xl:text-sm font-semibold leading-none">
          <Trans>Acceder</Trans>
        </span>
      </OutlinedButton>
    </Link>
  )
}
