import React from 'react'

export default function HouseError({ size, ...rest }) {
  return (
    <svg width={size} height={size} viewBox="0 0 520 520" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="m458.5 469.75h-15v-35c0-2.762-2.238-5-5-5h-20v-20c0-2.762-2.238-5-5-5h-10v-215h53.095c2.188 0 4.12-1.421 4.772-3.509s-.128-4.357-1.926-5.602l-80.941-56.043v-49.846c0-2.761-2.238-5-5-5h-35c-2.762 0-5 2.239-5 5v18.687l-77.154-53.421c-1.712-1.186-3.98-1.186-5.692 0l-203.095 140.623c-1.798 1.245-2.579 3.514-1.926 5.602.652 2.087 2.585 3.509 4.772 3.509h53.095v215h-10c-2.761 0-5 2.238-5 5v20h-20c-2.761 0-5 2.238-5 5v35h-15c-2.761 0-5 2.238-5 5s2.239 5 5 5h410c2.762 0 5-2.238 5-5s-2.238-5-5-5zm-115-390h25v65h-25zm-90-29.542 80 55.392v44.149c0 2.761 2.238 5 5 5h35c2.762 0 5-2.239 5-5v-12.991l62.09 42.991c-12.357 0-306.086 0-317.569 0l128.369-90.919c2.254-1.596 2.786-4.716 1.19-6.97-1.596-2.253-4.715-2.787-6.97-1.19l-139.891 99.08h-39.309zm-140 169.542h85c2.761 0 5-2.239 5-5s-2.239-5-5-5h-85v-20h280v20h-85c-2.762 0-5 2.239-5 5s2.238 5 5 5h85v185h-280zm-15 195h310v15h-310zm-25 55v-30h360v30z"
        fill="currentColor"
      />
      <circle cx="456" cy="127.25" r="7.5" fill="currentColor" />
      <circle cx="396" cy="44.75" r="7.5" fill="currentColor" />
      <circle cx="166" cy="47.25" r="7.5" fill="currentColor" />
      <circle cx="66" cy="117.25" r="7.5" fill="currentColor" />
      <circle cx="66" cy="262.25" r="7.5" fill="currentColor" />
      <circle cx="436" cy="352.25" r="7.5" fill="currentColor" />
      <path
        d="m448.5 214.75c-16.542 0-30 13.458-30 30s13.458 30 30 30 30-13.458 30-30-13.458-30-30-30zm0 50c-11.028 0-20-8.972-20-20s8.972-20 20-20 20 8.972 20 20-8.972 20-20 20z"
        fill="currentColor"
      />
      <path
        d="m83.5 359.75c0-13.785-11.215-25-25-25s-25 11.215-25 25 11.215 25 25 25 25-11.215 25-25zm-40 0c0-8.272 6.729-15 15-15s15 6.728 15 15-6.729 15-15 15-15-6.728-15-15z"
        fill="currentColor"
      />
      <path
        d="m276.306 213.208c-9.274-16.064-32.465-16.086-41.753 0l-83.937 145.382c-9.274 16.065 2.302 36.16 20.876 36.16h167.873c18.552 0 30.164-20.073 20.877-36.16zm63.059 171.542h-167.873c-10.854 0-17.65-11.746-12.216-21.16l83.936-145.382c5.427-9.4 18.998-9.413 24.433 0l83.936 145.382c5.429 9.402-1.346 21.16-12.216 21.16z"
        fill="currentColor"
      />
      <path
        d="m253.465 233.379c-10.341 0-18.755 8.413-18.755 18.754v58.946c0 10.341 8.413 18.754 18.755 18.754 10.341 0 18.754-8.413 18.754-18.754v-58.946c0-10.341-8.413-18.754-18.754-18.754zm8.754 77.7c0 4.827-3.927 8.754-8.754 8.754-4.828 0-8.755-3.927-8.755-8.754v-58.946c0-4.827 3.927-8.754 8.755-8.754 4.827 0 8.754 3.927 8.754 8.754z"
        fill="currentColor"
      />
      <path
        d="m253.465 339.482c-10.341 0-18.755 8.413-18.755 18.754s8.413 18.754 18.755 18.754c10.341 0 18.754-8.413 18.754-18.754s-8.413-18.754-18.754-18.754zm0 27.508c-4.828 0-8.755-3.927-8.755-8.754s3.927-8.754 8.755-8.754c4.827 0 8.754 3.927 8.754 8.754 0 4.828-3.927 8.754-8.754 8.754z"
        fill="currentColor"
      />
    </svg>
  )
}
