import React from 'react'

export default function DisableCamera({ size, ...rest }) {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_5239_11508)">
        <path
          d="M24.7406 4.23656C24.5802 4.14384 24.3823 4.14281 24.2208 4.23554L17.7083 7.95639V5.72929C17.7083 4.86781 17.0073 4.16679 16.1458 4.16679H2.69897C0.844775 4.16679 0 8.48656 0 12.5001C0 16.5137 0.844775 20.8335 2.69897 20.8335H16.1458C17.0073 20.8335 17.7083 20.1325 17.7083 19.271V17.0439L24.2208 20.7647C24.3011 20.8106 24.3906 20.8335 24.4792 20.8335C24.5698 20.8335 24.6594 20.8106 24.7407 20.7637C24.9011 20.67 25 20.4991 25 20.3127V4.68768C25 4.50219 24.901 4.33031 24.7406 4.23656Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_5239_11508">
          <rect width={size} height={size} fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  )
}
