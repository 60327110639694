import React, { useEffect, useState } from 'react'
import Modal from '../../generic/Modal'
import SmallTitle from '../../generic/SmallTitle'
import Card from '../../generic/Card'
import { t, Trans } from '@lingui/macro'
import { FiCopy } from 'react-icons/fi'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Tooltip } from 'react-tippy'

export default function HelpModal({ open, onClose }) {
  const [emailTooltipOpen, setEmailTooltipOpen] = useState(false)

  useEffect(() => {
    if (emailTooltipOpen) {
      setTimeout(() => setEmailTooltipOpen(false), 1500)
    }
  }, [emailTooltipOpen])

  return (
    <Modal open={open} onClose={onClose} maxWidth={700} animate showCloseButton>
      <Card>
        <Card.Body>
          <SmallTitle>
            <Trans>Ayuda</Trans>
          </SmallTitle>
          <p className="text-gray-700">
            <Trans>
              ¿Necesitas ayuda en algo o tuviste algún problema? Escríbenos al correo electrónico a continuación y nos
              comunicaremos contigo a la brevedad.
            </Trans>
          </p>

          <div className="text-center mt-5">
            <Tooltip
              open={emailTooltipOpen}
              className="max-w-full"
              animation="shift"
              position="top"
              title={t`Correo electrónico copiado`}
            >
              <CopyToClipboard text="soporte@netmin.cl" onCopy={() => setEmailTooltipOpen(true)}>
                <button className="inline-block max-w-full relative bg-gray-200 rounded-lg">
                  <div className="flex items-center px-5 py-2">
                    <span className="text-gray-700 truncate">soporte@netmin.cl</span>
                    <div className="ml-3 text-gray-600">
                      <FiCopy />
                    </div>
                  </div>
                </button>
              </CopyToClipboard>
            </Tooltip>
          </div>
        </Card.Body>
      </Card>
    </Modal>
  )
}
