import React from 'react'

export default function Disconnect({ ...rest }) {
  return (
    <svg width="41" height="14" viewBox="0 0 41 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M0 8.84853L0.025725 12.1619L0.0282975 12.1671C0.036015 12.9234 0.652129 13.4636 1.34285 13.4585L1.35056 13.4636L10.7569 13.3877C11.4836 13.3826 12.0522 12.7922 12.0432 12.077L12.0161 8.76107L11.5595 8.76364C11.7023 7.38735 13.3744 7.06193 16.7045 6.69407L24.7192 6.62718C26.6485 6.81497 28.5175 7.16097 28.6525 8.65946L28.3965 8.66203L28.4236 11.9716L28.4248 11.9754C28.4313 12.7394 29.05 13.2771 29.7407 13.2694L29.7484 13.2771L39.1535 13.1973C39.8802 13.1961 40.4461 12.6031 40.441 11.8867L40.414 8.57456L40.0744 8.57714C39.7426 6.56287 36.4176 0.0595884 20.7125 0.000420909C4.41055 -0.0613191 0.594248 6.68635 0.398738 8.84596L0 8.84853Z"
        fill="currentColor"
      />
    </svg>
  )
}
