import React from 'react'
import classnames from 'classnames'
import useScroll from '../../../hooks/useScroll'
import Container from '../Container'
import Link from 'next/link'
import DesktopMenu from './components/DesktopMenu'
import AccountOptions from './components/AccountOptions'
import LocaleOptions from './components/LocaleOptions'
import Search from './components/Search'
import { useAuth } from '../../../providers/authentication/context'
import AccessButton from './components/AccessButton'

export default function NavBar({ tags }) {
  const { user } = useAuth()
  const { value } = useScroll()

  return (
    <nav
      className={classnames(
        'sticky top-0 z-40 bg-white h-12 md:h-16 border-b border-gray-200 transition-shadow duration-300',
        value > 0 ? 'shadow-md' : 'shadow-none'
      )}
    >
      <Container className="h-full">
        <div className="h-full flex items-center justify-between lg:-mx-3">
          <div className="flex flex-1 sm:flex-auto items-center lg:px-3 space-x-6">
            <Link href="/">
              <a>
                <img src="/static/images/netMin.svg" alt="NetMin" className="h-5 md:h-6" />
              </a>
            </Link>
            <div className="flex-1">
              <Search />
            </div>
          </div>

          <div className="h-full flex lg:px-3">
            <div className="h-full border-r hidden lg:block">
              {user ? <DesktopMenu institutionTags={tags} /> : null}
            </div>
            <div className="flex items-center h-full pl-3 space-x-2 md:space-x-3">
              {user ? (
                <div className="h-full">
                  <AccountOptions />
                </div>
              ) : null}
              {!user ? <AccessButton /> : null}
              <LocaleOptions />
            </div>
          </div>
        </div>
      </Container>
    </nav>
  )
}
