import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FiChevronDown } from 'react-icons/fi'
import classnames from 'classnames'

function Wrapper({ children, href, as, useLink, className }) {
  if (useLink) {
    return (
      <Link href={href} as={as}>
        <a className={classnames('inline-block h-full hover:bg-gray-200 hover:bg-opacity-50', className)}>{children}</a>
      </Link>
    )
  } else {
    return (
      <div
        className={classnames('inline-block h-full hover:bg-gray-200 hover:bg-opacity-50 cursor-pointer', className)}
      >
        {children}
      </div>
    )
  }
}

export default function DesktopItem({
  children,
  label,
  href,
  as,
  exact = false,
  accessory,
  className,
  useLink = true,
  bottomArrow = false,
  activeRoutes,
}) {
  const { asPath } = useRouter()
  const active =
    activeRoutes?.length > 0 ? activeRoutes.includes(asPath) : exact ? asPath === href : asPath.indexOf(href) > -1

  return (
    <Wrapper href={href} as={as} useLink={!children && useLink} active={active} className={className}>
      <div className="group h-full flex flex-col items-center justify-center relative px-1 lg:px-2 xl:px-3">
        {active ? <div className="absolute top-0 w-full h-1 bg-orange-500" /> : null}

        {accessory ? <div className="mb-1">{accessory({ active })}</div> : null}

        <span className="block text-gray-700 text-xs xl:text-sm font-semibold leading-none inline-flex items-center">
          <span>{label}</span>
          {bottomArrow ? <FiChevronDown className="ml-1" /> : null}
        </span>
        {children ? (
          <div className="absolute bottom-0 left-0 bg-white transform translate-y-full py-2 hidden group-hover:block rounded-b border-t border-gray-200 shadow-lg">
            {children}
          </div>
        ) : null}
      </div>
    </Wrapper>
  )
}
