import React from 'react'

export default function Webinar({ size, color, ...rest }) {
  return (
    <svg width={size} height={size} viewBox="0 0 85 73" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M84.2308 11.2307V65.9807C84.2308 67.8423 83.4913 69.6277 82.1749 70.9441C80.8585 72.2604 79.0732 72.9999 77.2115 72.9999H7.01923C5.15762 72.9999 3.37225 72.2604 2.05589 70.9441C0.739524 69.6277 0 67.8423 0 65.9807V11.2307H84.2308Z"
        fill="#E2E7F6"
      />
      <path
        d="M0 11.2307V65.9807C0.00740409 66.7897 0.156108 67.5912 0.439404 68.349C1.19721 68.6323 1.99869 68.781 2.80769 68.7884H73C74.8616 68.7884 76.647 68.0489 77.9633 66.7325C79.2797 65.4162 80.0192 63.6308 80.0192 61.7692V11.2307H0Z"
        fill="#F2F2F2"
      />
      <path d="M56.1543 42.1157H78.6158V61.7696H56.1543V42.1157Z" fill="#939393" />
      <path
        d="M47.7306 16.8462H8.42293C6.87228 16.8462 5.61523 18.1032 5.61523 19.6539V53.3462C5.61523 54.8968 6.87228 56.1539 8.42293 56.1539H47.7306C49.2813 56.1539 50.5383 54.8968 50.5383 53.3462V19.6539C50.5383 18.1032 49.2813 16.8462 47.7306 16.8462Z"
        fill="#686868"
      />
      <path
        d="M8.42293 16.8462C7.67828 16.8462 6.96413 17.142 6.43759 17.6685C5.91104 18.1951 5.61523 18.9092 5.61523 19.6539V51.9423H43.5191C44.2637 51.9423 44.9779 51.6465 45.5044 51.12C46.031 50.5934 46.3268 49.8793 46.3268 49.1347V16.8462H8.42293Z"
        fill="#777777"
      />
      <path
        d="M84.2308 11.2308V7.01923C84.2308 5.15761 83.4913 3.37225 82.1749 2.05589C80.8585 0.739524 79.0732 0 77.2115 0H7.01923C5.15762 0 3.37225 0.739524 2.05589 2.05589C0.739524 3.37225 0 5.15761 0 7.01923V11.2308H84.2308Z"
        fill="#FF931E"
      />
      <path d="M7.01953 4.21167H9.82722V7.01936H7.01953V4.21167Z" fill="#4F4F4F" />
      <path d="M12.6348 4.21143H15.4425V7.01912H12.6348V4.21143Z" fill="#4F4F4F" />
      <path d="M18.25 4.21143H21.0577V7.01912H18.25V4.21143Z" fill="#4F4F4F" />
      <path d="M60.3652 4.21143H77.2114V7.01912H60.3652V4.21143Z" fill="#4F4F4F" />
      <path
        d="M30.8851 22.4617H25.2697C22.1684 22.4617 19.6543 24.9758 19.6543 28.0771V36.5001C19.6543 39.6014 22.1684 42.1155 25.2697 42.1155H30.8851C33.9864 42.1155 36.5005 39.6014 36.5005 36.5001V28.0771C36.5005 24.9758 33.9864 22.4617 30.8851 22.4617Z"
        fill="#FBE1CA"
      />
      <path
        d="M36.5005 30.8847H32.2889C31.5443 30.8847 30.8301 30.5889 30.3036 30.0624C29.777 29.5358 29.4812 28.8217 29.4812 28.0771C26.7348 29.9078 23.508 30.8847 20.2074 30.8847H19.6543V28.0771C19.6543 26.5878 20.2459 25.1595 21.299 24.1064C22.3521 23.0533 23.7804 22.4617 25.2697 22.4617H30.8851C32.3744 22.4617 33.8027 23.0533 34.8557 24.1064C35.9088 25.1595 36.5005 26.5878 36.5005 28.0771V30.8847Z"
        fill="#C18E59"
      />
      <path
        d="M32.2883 47.7307V41.9385C31.8296 42.056 31.358 42.1155 30.8845 42.1154H25.2691C24.7956 42.1155 24.3239 42.056 23.8652 41.9385V47.7307H32.2883Z"
        fill="#F2B9A1"
      />
      <path
        d="M12.6348 56.1535V53.3459C12.6348 51.8566 13.2264 50.4283 14.2795 49.3752C15.3326 48.3221 16.7609 47.7305 18.2502 47.7305H37.904C39.3933 47.7305 40.8216 48.3221 41.8747 49.3752C42.9278 50.4283 43.5194 51.8566 43.5194 53.3459V56.1535"
        fill="#3B5892"
      />
      <path d="M8.42188 61.7695H47.7296V67.3849H8.42188V61.7695Z" fill="#213D68" />
      <path
        d="M44.9219 63.1734V61.7695H8.42188V64.5772H43.518C43.8904 64.5772 44.2474 64.4293 44.5107 64.166C44.774 63.9028 44.9219 63.5457 44.9219 63.1734Z"
        fill="#3B5892"
      />
      <path d="M56.1543 16.8462H78.6158V36.5H56.1543V16.8462Z" fill="#B5B5B5" />
      <path
        d="M75.8081 32.2885V16.8462H56.1543V33.6923H74.4043C74.7766 33.6923 75.1337 33.5444 75.397 33.2812C75.6602 33.0179 75.8081 32.6608 75.8081 32.2885Z"
        fill="#C1C1C1"
      />
      <path
        d="M75.8081 57.558V42.1157H56.1543V58.9619H74.4043C74.7766 58.9619 75.1337 58.814 75.397 58.5507C75.6602 58.2874 75.8081 57.9304 75.8081 57.558Z"
        fill="#A0A0A0"
      />
      <path
        d="M67.3834 30.8847C69.7094 30.8847 71.595 28.9992 71.595 26.6732C71.595 24.3472 69.7094 22.4617 67.3834 22.4617C65.0574 22.4617 63.1719 24.3472 63.1719 26.6732C63.1719 28.9992 65.0574 30.8847 67.3834 30.8847Z"
        fill="#FBE1CA"
      />
      <path
        d="M61.7695 36.4997C61.7695 35.0104 62.3612 33.5821 63.4142 32.529C64.4673 31.4759 65.8956 30.8843 67.3849 30.8843C68.8742 30.8843 70.3025 31.4759 71.3556 32.529C72.4087 33.5821 73.0003 35.0104 73.0003 36.4997"
        fill="#3B5892"
      />
      <path
        d="M67.3834 56.1535C69.7094 56.1535 71.595 54.268 71.595 51.942C71.595 49.616 69.7094 47.7305 67.3834 47.7305C65.0574 47.7305 63.1719 49.616 63.1719 51.942C63.1719 54.268 65.0574 56.1535 67.3834 56.1535Z"
        fill="#FBE1CA"
      />
      <path
        d="M61.7695 61.7692C61.7695 60.2799 62.3612 58.8516 63.4142 57.7985C64.4673 56.7454 65.8956 56.1538 67.3849 56.1538C68.8742 56.1538 70.3025 56.7454 71.3556 57.7985C72.4087 58.8516 73.0003 60.2799 73.0003 61.7692"
        fill="#F4A93C"
      />
      <path
        d="M43.5206 26.6732C42.9653 26.6732 42.4224 26.5086 41.9607 26.2001C41.499 25.8916 41.1391 25.4531 40.9266 24.94C40.7141 24.427 40.6585 23.8624 40.7668 23.3178C40.8752 22.7732 41.1426 22.2729 41.5352 21.8802C41.9279 21.4876 42.4282 21.2201 42.9728 21.1118C43.5175 21.0035 44.082 21.0591 44.595 21.2716C45.1081 21.4841 45.5466 21.844 45.8551 22.3057C46.1636 22.7674 46.3283 23.3102 46.3283 23.8656C46.3283 24.6102 46.0325 25.3243 45.5059 25.8509C44.9794 26.3774 44.2652 26.6732 43.5206 26.6732Z"
        fill="#F2F2F2"
      />
      <path
        d="M36.9331 52.9682C37.6778 52.9682 38.3919 52.6724 38.9185 52.1459C39.445 51.6193 39.7408 50.9052 39.7408 50.1605V48.0632C39.1513 47.8499 38.5301 47.7375 37.9032 47.7305H18.2494C16.8287 47.7332 15.4622 48.2758 14.4266 49.2483C13.391 50.2208 12.7638 51.5505 12.6719 52.9682H36.9331Z"
        fill="#4C6CB5"
      />
    </svg>
  )
}
