import React from 'react'
import WebViewCompatibleLink from '../../generic/WebViewCompatibleLink'
import { t, Trans } from '@lingui/macro'
import { getCountry } from '../../../utils/countries'
import { FiChevronRight } from 'react-icons/fi'
import { BsImageFill } from 'react-icons/bs'
import Image from 'next/image'
import { useSearchbar } from '../../generic/SearchBar'

export default function SearchResultItemCompany({ company }) {
  const { close } = useSearchbar()

  return (
    <WebViewCompatibleLink href={`/profile/c/${company.UUID}`} onClick={close} className="w-full block">
      <div className="w-full bg-white hover:bg-gray-100 p-3 overflow-hidden-z">
        <div className="flex items-center w-full space-x-3 box-border">
          <div>
            {company.Logo ? (
              <div className="rounded-full overflow-hidden-z w-12 sm:w-16 h-12 sm:h-16 bg-gray-200">
                <Image src={company.Logo?.Thumbnail} layout="fill" objectFit="contain" />
              </div>
            ) : (
              <div className="flex items-center justify-center w-12 sm:w-16 h-12 sm:h-16 rounded-full bg-gray-200">
                <BsImageFill className="text-3xl opacity-75 text-gray-400" />
              </div>
            )}
          </div>
          <div className="flex-1 overflow-hidden-z space-y-1">
            <span className="block text-xs font-semibold text-orange-500 group-hover:text-white leading-none uppercase m-0">
              {company.Type === 'company' ? <Trans>Empresa</Trans> : null}
              {company.Type === 'institution' ? <Trans>Institución</Trans> : null}
            </span>
            <h4 className="flex items-center text-gray-800 font-semibold group-hover:text-white leading-none text-sm sm:text-base truncate">
              {company.Name}

              <img
                width={14}
                height={14}
                src="/static/images/certified.svg"
                alt="Usuario verificado"
                className="inline-block ml-2"
              />
            </h4>
            <p className="block text-sm font-medium leading-tight text-gray-700 group-hover:text-white truncate">
              {getCountry(company.Country)?.name || t`Sin país`}
            </p>
          </div>
          <div>
            <FiChevronRight />
          </div>
        </div>
      </div>
    </WebViewCompatibleLink>
  )
}
