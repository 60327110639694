import React from 'react'

export default function House2({ size, ...rest }) {
  return (
    <svg width={size} height={size} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M16.2759 22.9932H6.71927C5.63854 23.0334 4.58562 22.6454 3.78929 21.9136C2.99297 21.1819 2.51757 20.1654 2.46641 19.0852V13.962C2.46641 13.7443 2.55291 13.5355 2.70688 13.3815C2.86085 13.2275 3.06968 13.141 3.28742 13.141C3.50517 13.141 3.714 13.2275 3.86797 13.3815C4.02194 13.5355 4.10844 13.7443 4.10844 13.962V19.0852C4.1589 19.73 4.46101 20.3292 4.94952 20.7532C5.43803 21.1772 6.07372 21.392 6.71927 21.3512H16.2759C16.9215 21.392 17.5571 21.1772 18.0457 20.7532C18.5342 20.3292 18.8363 19.73 18.8867 19.0852V13.962C18.8867 13.7443 18.9732 13.5355 19.1272 13.3815C19.2812 13.2275 19.49 13.141 19.7077 13.141C19.9255 13.141 20.1343 13.2275 20.2883 13.3815C20.4423 13.5355 20.5288 13.7443 20.5288 13.962V19.0852C20.4776 20.1654 20.0022 21.1819 19.2059 21.9136C18.4096 22.6454 17.3566 23.0334 16.2759 22.9932ZM22.7537 12.0819C22.8307 12.0056 22.8918 11.9148 22.9334 11.8147C22.9751 11.7147 22.9966 11.6074 22.9966 11.499C22.9966 11.3906 22.9751 11.2833 22.9334 11.1832C22.8918 11.0832 22.8307 10.9924 22.7537 10.9161L12.0805 0.242855C12.0042 0.165903 11.9134 0.104824 11.8133 0.0631418C11.7133 0.0214599 11.606 0 11.4976 0C11.3892 0 11.2819 0.0214599 11.1818 0.0631418C11.0818 0.104824 10.991 0.165903 10.9147 0.242855L0.241454 10.9161C0.0868536 11.0707 0 11.2803 0 11.499C0 11.7176 0.0868536 11.9273 0.241454 12.0819C0.396054 12.2365 0.605738 12.3234 0.824375 12.3234C1.04301 12.3234 1.2527 12.2365 1.4073 12.0819L11.4976 1.98341L21.5879 12.0819C21.6642 12.1589 21.755 12.2199 21.8551 12.2616C21.9551 12.3033 22.0624 12.3248 22.1708 12.3248C22.2792 12.3248 22.3865 12.3033 22.4865 12.2616C22.5866 12.2199 22.6774 12.1589 22.7537 12.0819Z"
        fill="currentColor"
      />
    </svg>
  )
}
