import React from 'react'

export default function EnableCamera({ size, ...rest }) {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_5239_11481)">
        <path
          d="M2.44152 4.6514C2.34153 4.55141 2.23006 4.48369 2.06342 4.49931C1.92177 4.50141 1.78741 4.56181 1.69156 4.66595C0.664565 5.77112 0 8.84596 0 12.501C0 16.5143 0.844731 20.8339 2.69883 20.8339H16.145C16.6168 20.8339 17.0606 20.611 17.3647 20.2214C17.5262 20.0142 17.5085 19.7183 17.322 19.533L2.44152 4.6514Z"
          fill="currentColor"
        />
        <path
          d="M24.8487 24.1119L17.8772 17.1404L24.2216 20.7652C24.3019 20.812 24.3914 20.8349 24.48 20.8349C24.5706 20.8349 24.6602 20.812 24.7414 20.7652C24.9018 20.6714 25.0008 20.5006 25.0008 20.3142V4.68999C25.0008 4.5046 24.9018 4.33273 24.7414 4.23899C24.5811 4.14627 24.3831 4.14524 24.2217 4.23796L17.7095 7.95861V5.73158C17.7095 4.87015 17.0086 4.16916 16.1471 4.16916H4.90494L0.890603 0.154777C0.687488 -0.0483372 0.357281 -0.0483372 0.154167 0.154777C-0.0489475 0.357891 -0.0489475 0.688099 0.154167 0.891213L24.1113 24.8483C24.2133 24.9504 24.3466 25.0004 24.48 25.0004C24.6133 25.0004 24.7466 24.9494 24.8487 24.8483C25.0518 24.6452 25.0518 24.315 24.8487 24.1119Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_5239_11481">
          <rect width={size} height={size} fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  )
}
