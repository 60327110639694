import React from 'react'

export default function Drone({ size, ...rest }) {
  return (
    <svg width={size} height={size} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M22.3262 2.74023H16.9355C16.5631 2.74023 16.2617 3.04162 16.2617 3.41406C16.2617 3.78651 16.5631 4.08789 16.9355 4.08789H18.957V5.43555H18.2832C17.9108 5.43555 17.6094 5.73693 17.6094 6.10938V7.45703C17.6094 8.33436 18.1744 9.0753 18.957 9.3544V10.1523C18.957 10.5241 18.655 10.8262 18.2832 10.8262H17.6094V10.1523C17.6094 9.0359 16.7043 8.13086 15.5879 8.13086H7.45703C6.34059 8.13086 5.43555 9.0359 5.43555 10.1523V10.8262H4.76172C4.38995 10.8262 4.08789 10.5241 4.08789 10.1523V9.3544C4.87052 9.0753 5.43555 8.33436 5.43555 7.45703V6.10938C5.43555 5.73693 5.13417 5.43555 4.76172 5.43555H4.08789V4.08789H6.10938C6.48182 4.08789 6.7832 3.78651 6.7832 3.41406C6.7832 3.04162 6.48182 2.74023 6.10938 2.74023H0.673828C0.301381 2.74023 0 3.04162 0 3.41406C0 3.78651 0.301381 4.08789 0.673828 4.08789H2.74023V5.43555H2.06641C1.69396 5.43555 1.39258 5.73693 1.39258 6.10938V7.45703C1.39258 8.33436 1.95761 9.0753 2.74023 9.3544V10.1523C2.74023 11.267 3.64703 12.1738 4.76172 12.1738H5.43555V12.8477C5.43555 13.2198 5.73724 13.5215 6.10938 13.5215H16.9355C17.3077 13.5215 17.6094 13.2198 17.6094 12.8477V12.1738H18.2832C19.3979 12.1738 20.3047 11.267 20.3047 10.1523V9.3544C21.0873 9.0753 21.6523 8.33436 21.6523 7.45703V6.10938C21.6523 5.73693 21.351 5.43555 20.9785 5.43555H20.3047V4.08789H22.3262C22.6986 4.08789 23 3.78651 23 3.41406C23 3.04162 22.6986 2.74023 22.3262 2.74023ZM11.4987 11.5C11.1266 11.5 10.8249 11.1983 10.8249 10.8262C10.8249 10.454 11.1266 10.1523 11.4987 10.1523C11.8709 10.1523 12.1725 10.454 12.1725 10.8262C12.1725 11.1983 11.8709 11.5 11.4987 11.5Z"
        fill="currentColor"
      />
      <path
        d="M19.6309 18.9121H18.957V16.8906C18.957 16.7117 18.886 16.5405 18.7596 16.4142L16.0643 15.0666C16.0292 15.0314 15.9855 15.0133 15.9452 14.9875L16.0044 14.8691H7.04052L7.09968 14.9875C7.05947 15.0133 7.01576 15.0314 6.98064 15.0666L4.28532 16.4142C4.15896 16.5405 4.08789 16.7117 4.08789 16.8906V18.9121H3.41406C3.04162 18.9121 2.74023 19.2135 2.74023 19.5859C2.74023 19.9584 3.04162 20.2598 3.41406 20.2598H6.10938C6.48182 20.2598 6.7832 19.9584 6.7832 19.5859C6.7832 19.2135 6.48182 18.9121 6.10938 18.9121H5.43555V17.1696L7.67508 16.1383L8.20193 17.192C8.31643 17.4203 8.54935 17.5645 8.80469 17.5645H14.2402C14.4956 17.5645 14.7285 17.4203 14.843 17.192L15.3698 16.1383L17.6094 17.1696V18.9121H16.9355C16.5631 18.9121 16.2617 19.2135 16.2617 19.5859C16.2617 19.9584 16.5631 20.2598 16.9355 20.2598H19.6309C20.0033 20.2598 20.3047 19.9584 20.3047 19.5859C20.3047 19.2135 20.0033 18.9121 19.6309 18.9121Z"
        fill="currentColor"
      />
    </svg>
  )
}
