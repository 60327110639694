import React from 'react'

export default function House({ size, ...rest }) {
  return (
    <svg width={size} height={size} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M22.3779 10.0039C22.3774 10.0034 22.3769 10.0028 22.3764 10.0023L12.9942 0.620483C12.5943 0.220398 12.0626 0 11.497 0C10.9315 0 10.3998 0.220222 9.99968 0.620308L0.622414 9.9974C0.619256 10.0006 0.616097 10.0039 0.612939 10.007C-0.208289 10.833 -0.206886 12.1731 0.616975 12.997C0.993371 13.3736 1.49049 13.5917 2.02201 13.6145C2.0436 13.6166 2.06535 13.6176 2.08729 13.6176H2.46123V20.5221C2.46123 21.8884 3.57287 23 4.93948 23H8.61008C8.98209 23 9.28391 22.6984 9.28391 22.3262V16.9131C9.28391 16.2896 9.79104 15.7825 10.4145 15.7825H12.5795C13.203 15.7825 13.7101 16.2896 13.7101 16.9131V22.3262C13.7101 22.6984 14.0118 23 14.3839 23H18.0546C19.4212 23 20.5328 21.8884 20.5328 20.5221V13.6176H20.8795C21.4449 13.6176 21.9766 13.3974 22.3769 12.9973C23.2016 12.1721 23.202 10.8297 22.3779 10.0039Z"
        fill="currentColor"
      />
    </svg>
  )
}
