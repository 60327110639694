import React from 'react'
import useSWR from 'swr'
import { useAuth } from '../../../providers/authentication/context'
import useService from '../../../services/hooks/useService'
import NotificationsService from '../../../services/NotificationsService'

export default function UnreadNotificationsIndicator() {
  const countCompaniesUnreadNotifications = (companiesSWRObject) => {
    const companiesKeys = Object.keys(companiesSWRObject)
    let counter = 0
    companiesKeys.forEach((compUUID) => {
      const actualCompanyNotificationCount = companiesSWRObject[compUUID].data?.Count || 0
      counter += actualCompanyNotificationCount
    })

    return counter
  }

  const { user } = useAuth()
  const notificationsService = useService(NotificationsService)
  const { data: unreadNotifications } = useSWR(
    [user.UUID, '/notifications/?Read=false&offset=0&limit=0&RecipientUUID'],
    notificationsService.findReadNotificationsCountByRecipientUUID,
    { refreshInterval: 10000 }
  )

  const companiesSWR = {}

  if (user?.Companies.length > 0) {
    for (let i = 0; i < 3; i++) {
      const actualCompany = user?.Companies[i]
      if (actualCompany) {
        companiesSWR[actualCompany?.UUID] = useSWR(
          [actualCompany?.UUID, '/notifications/?Read=false&offset=0&limit=0&RecipientUUID'],
          notificationsService.findReadNotificationsCountByRecipientUUID,
          { refreshInterval: 20000 }
        )
      } else {
        break
      }
    }
  }

  const count = unreadNotifications?.Count + countCompaniesUnreadNotifications(companiesSWR) || 0

  return count ? (
    <div className="rounded-full overflow-hidden-z border-2 border-white">
      <div className="flex items-center justify-center leading-none w-4 h-4 rounded-full bg-red-500 transform scale-90">
        <span className="text-white text-xs">{count > 9 ? '+9' : count}</span>
      </div>
    </div>
  ) : null
}
