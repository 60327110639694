import React from 'react'

export default function Share({ size, color, ...rest }) {
  return (
    <svg width={size} height={size} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0)">
        <path
          d="M13.2818 14.9998H1.71939C0.771255 14.9998 0.000732422 14.2292 0.000732422 13.2811V4.21866C0.000732422 3.27052 0.771255 2.5 1.71939 2.5H3.90691C4.16565 2.5 4.37565 2.71 4.37565 2.96875C4.37565 3.22749 4.16565 3.43749 3.90691 3.43749H1.71939C1.28875 3.43749 0.938223 3.78802 0.938223 4.21866V13.2811C0.938223 13.7117 1.28875 14.0623 1.71939 14.0623H13.2818C13.7124 14.0623 14.0631 13.7117 14.0631 13.2811V7.96862C14.0631 7.70987 14.2731 7.49987 14.5318 7.49987C14.7906 7.49987 15.0006 7.70987 15.0006 7.96862V13.2811C15.0006 14.2292 14.2299 14.9998 13.2818 14.9998Z"
          fill={color}
        />
        <path
          d="M4.21757 9.99197C4.18324 9.99197 4.14879 9.98819 4.11446 9.97938C3.90263 9.93006 3.74951 9.74878 3.74951 9.53135V8.59386C3.74951 5.2339 6.48325 2.50017 9.8432 2.50017H9.99941V0.468861C9.99941 0.277632 10.1157 0.105743 10.2932 0.033875C10.47 -0.0373066 10.6731 0.00572274 10.8056 0.143852L14.8681 4.36256C15.0431 4.54383 15.0431 4.83131 14.8681 5.01258L10.8056 9.23129C10.6731 9.36942 10.4687 9.41199 10.2932 9.34126C10.1157 9.26939 9.99941 9.09751 9.99941 8.90628V6.87509H9.25693C7.29074 6.87509 5.52379 7.96753 4.64512 9.72567C4.56444 9.88817 4.39507 9.99197 4.21757 9.99197ZM9.8432 3.43766C7.21818 3.43766 5.04451 5.40945 4.72637 7.95002C5.86253 6.6826 7.49192 5.93759 9.25693 5.93759H10.4682C10.7269 5.93759 10.9369 6.14759 10.9369 6.40634V7.7438L13.88 4.68757L10.9369 1.63134V2.96891C10.9369 3.22766 10.7269 3.43766 10.4682 3.43766H9.8432Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
