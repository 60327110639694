import React from 'react'
import FloatingCard from '../../../FloatingCard'
import OutlinedButton from '../../../OutlinedButton'
import { FiChevronDown } from 'react-icons/fi'
import { useLanguage } from '../../../../../providers/language/context'
import clsx from 'clsx'

const renderTrigger = (onToggle, textClassName) => {
  const { locale } = useLanguage()

  return (
    <OutlinedButton className={clsx(textClassName, 'p-2 rounded-lg')} onClick={onToggle}>
      <div className={clsx('flex items-center space-x-2')}>
        {locale === 'es' ? (
          <React.Fragment>
            <img src="/static/images/flags/es.png" alt="Español" className="w-4 h-4" />
            <span className={clsx('text-sm font-bold  text-white hidden sm:block')}>Esp</span>
          </React.Fragment>
        ) : null}

        {locale === 'en' ? (
          <React.Fragment>
            <img src="/static/images/flags/uk.png" alt="English" className="w-4 h-4" />
            <span className={clsx('text-sm font-bold  hidden sm:block')}>Eng</span>
          </React.Fragment>
        ) : null}
        <FiChevronDown className="ml-1 hidden sm:block" />
      </div>
    </OutlinedButton>
  )
}

export default function LocaleOptions({ textClassName = '' }) {
  const { enable } = useLanguage()

  return (
    <div className="h-full relative inline-flex items-center">
      <FloatingCard renderTrigger={(onToggle) => renderTrigger(onToggle, textClassName)}>
        <FloatingCard.Button
          onClick={() => enable('es')}
          renderCustomIcon={() => (
            <img src="/static/images/flags/es.png" alt="Español" className="inline-block w-5 h-5" />
          )}
        >
          <span className="leading-none">Español</span>
        </FloatingCard.Button>
        <FloatingCard.Button
          onClick={() => enable('en')}
          renderCustomIcon={() => (
            <img src="/static/images/flags/uk.png" alt="Español" className="inline-block w-5 h-5" />
          )}
        >
          <span className="leading-none">English</span>
        </FloatingCard.Button>
      </FloatingCard>
    </div>
  )
}
