import React from 'react'

export default function Results({ results, sources }) {
  return results?.length > 0 ? (
    <div className="divide-y divide-gray-200">
      {results.map((result) => {
        const source = sources.find((source) => source.key === result._Type)
        return source ? <div>{source.renderItem(result)}</div> : null
      })}
    </div>
  ) : null
}
