import React from 'react'
import { Trans } from '@lingui/macro'

export default function Messages({ results, query }) {
  return !results || results.length === 0 ? (
    <div className="pt-2 px-2 sm:px-4 pb-2 sm:pb-4 min-h-20">
      {!results || !query ? (
        <div className="px-3 h-20 flex items-center justify-center w-full">
          <p className="m-0 leading-tight text-gray-600 text-center text-sm">
            <Trans>Escribe lo que deseas buscar para ver resultados.</Trans>
          </p>
        </div>
      ) : null}

      {results && results.length === 0 && query ? (
        <div className="px-3 h-20 flex items-center justify-center w-full">
          <p className="m-0 leading-tight text-gray-600 text-center text-sm">
            <Trans>No se encontraron resultados.</Trans>
          </p>
        </div>
      ) : null}
    </div>
  ) : null
}
