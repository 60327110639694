import React, { useRef } from 'react'
import { throttle } from 'lodash'

export default function useScroll(throttleTime = 100) {
  const [value, setValue] = React.useState(0)
  const [direction, setDirection] = React.useState('up')

  const lastScrollValue = useRef(0)

  const setScrollDirection = (scrollPosition) => {
    if (Math.abs(lastScrollValue.current - scrollPosition) > 20) {
      setDirection(scrollPosition > lastScrollValue.current ? 'down' : 'up')
      lastScrollValue.current = scrollPosition <= 0 ? 0 : scrollPosition
    }
  }

  const handleScroll = React.useCallback(
    throttle(() => {
      const scrollPosition = window.scrollY || document.documentElement.scrollTop
      setValue(scrollPosition)
      setScrollDirection(scrollPosition)
    }, throttleTime),
    []
  )

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return { value, direction }
}
