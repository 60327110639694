import React from 'react'

export default function Industry({ size, ...rest }) {
  return (
    <svg width={size} height={size} viewBox="0 0 35 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3479 20.479L14.4549 1.67175C14.1322 1.1145 13.3253 1.1145 13.0025 1.67175L2.10961 20.4789C1.78685 21.0362 2.1903 21.7328 2.8358 21.7328H24.6217C25.2672 21.7328 25.6706 21.0362 25.3479 20.479ZM15.5442 1.04484C14.7373 -0.348281 12.7201 -0.348282 11.9132 1.04484L1.02031 19.852C0.213429 21.2452 1.22203 22.9866 2.8358 22.9866H24.6217C26.2354 22.9866 27.2441 21.2452 26.4372 19.852L15.5442 1.04484Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.8533 11.2026L33.3347 20.5161C33.6595 21.068 33.2535 21.7579 32.6039 21.7579H21.6411C21.527 21.7579 21.4204 21.7366 21.3239 21.6986H19.6816C19.9852 22.4376 20.7139 22.9997 21.6411 22.9997H32.6039C34.228 22.9997 35.2431 21.275 34.431 19.8952L28.9496 10.5817C28.1375 9.20193 26.1074 9.20193 25.2953 10.5817L22.8097 14.805L23.5195 16.0826L26.3916 11.2026C26.7164 10.6507 27.5285 10.6507 27.8533 11.2026Z"
        fill="currentColor"
      />
    </svg>
  )
}
