import React from 'react'

export default function Date({ size, ...rest }) {
  return (
    <svg width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M10.7143 1.28571H9.42857V0.428579C9.42857 0.191878 9.2367 0 8.99999 0C8.76329 0 8.57141 0.191878 8.57141 0.428579V1.28571H3.42856V0.428579C3.42856 0.191878 3.23668 0 3.00001 0C2.76333 0 2.57143 0.191878 2.57143 0.428579V1.28571H1.28571C0.575633 1.28571 0 1.86135 0 2.57143V10.7143C0 11.4244 0.575633 12 1.28571 12H10.7143C11.4244 12 12 11.4244 12 10.7143V2.57143C12 1.86135 11.4244 1.28571 10.7143 1.28571ZM11.1428 10.7143C11.1428 10.951 10.951 11.1429 10.7143 11.1429H1.28571C1.04901 11.1429 0.857134 10.951 0.857134 10.7143V5.14285H11.1428V10.7143ZM11.1428 4.28572H0.857134V2.57143C0.857134 2.33472 1.04901 2.14285 1.28571 2.14285H2.57143V2.99998C2.57143 3.23668 2.7633 3.42856 3.00001 3.42856C3.23671 3.42856 3.42859 3.23668 3.42859 2.99998V2.14285H8.57144V2.99998C8.57144 3.23668 8.76332 3.42856 9.00002 3.42856C9.23672 3.42856 9.4286 3.23668 9.4286 2.99998V2.14285H10.7143C10.951 2.14285 11.1429 2.33472 11.1429 2.57143V4.28572H11.1428Z"
        fill="white"
      />
      <path
        d="M8.86418 6.12617C8.69809 5.96576 8.4348 5.96576 8.26874 6.12617L5.14315 9.25172L3.73186 7.84043C3.5616 7.676 3.29029 7.68072 3.12586 7.85095C2.96545 8.01704 2.96545 8.28034 3.12586 8.44642L4.84015 10.1607C5.00752 10.328 5.2788 10.328 5.44615 10.1607L8.8747 6.73216C9.03913 6.5619 9.03441 6.2906 8.86418 6.12617Z"
        fill="white"
      />
    </svg>
  )
}
