import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Calendar from './svg/Calendar'
import HouseError from './svg/HouseError'
import House from './svg/House'
import Mountains from './svg/Mountains'
import Drone from './svg/Drone'
import MessageCloud from './svg/MessageCloud'
import Magnifier from './svg/Magnifier'
import Bell from './svg/Bell'
import Dots from './svg/Dots'
import Date from './svg/Date'
import Share from './svg/Share'
import Webinar from './svg/Webinar'
import House2 from './svg/House2'
import Industry from './svg/Industry'
import Calendar2 from './svg/Calendar2'
import ChatCloud from './svg/ChatCloud'
import Bell2 from './svg/Bell2'
import Megaphone from './svg/Megaphone'
import Business from './svg/Business'
import SafetyGlasses from './svg/SafetyGlasses'
import TOS from './svg/TOS'
import Mute from './svg/Mute'
import Unnmute from './svg/Unnmute'
import EnableCamera from './svg/EnableCamera'
import DisableCamera from './svg/DisableCamera'
import Disconnect from './svg/Disconnect'

export default function Icon({ name, size = 16, className, color, style, indicator, ...rest }) {
  let Element = elements[name] || null

  return Element ? (
    <div className="relative self-center flex">
      <Element size={size} color={color} className={classnames('inline', className)} style={style} {...rest} />
      {indicator ? (
        <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2">{indicator}</div>
      ) : null}
    </div>
  ) : null
}

const elements = {
  calendar: Calendar,
  'house-error': HouseError,
  house: House,
  mountains: Mountains,
  drone: Drone,
  'message-cloud': MessageCloud,
  magnifier: Magnifier,
  bell: Bell,
  dots: Dots,
  date: Date,
  share: Share,
  webinar: Webinar,
  house2: House2,
  novelty: Megaphone,
  industry: Industry,
  calendar2: Calendar2,
  chatCloud: ChatCloud,
  bell2: Bell2,
  business: Business,
  'safety-glasses': SafetyGlasses,
  tos: TOS,

  mute: Mute,
  unmute: Unnmute,
  'enable-camera': EnableCamera,
  'disable-camera': DisableCamera,
  disconnect: Disconnect,
}

Icon.defaultProps = {
  name: 'home',
  size: 18,
  color: 'text',
}

Icon.propTypes = {
  name: PropTypes.oneOf(Object.keys(elements)),
  size: PropTypes.number,
  className: PropTypes.any,
  style: PropTypes.any,
}
