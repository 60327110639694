import React from 'react'

export default function TOS({ size, ...rest }) {
  return (
    <svg width={size} height={size} viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <circle cx="37" cy="37" r="35.5" fill="transparent" stroke="currentColor" strokeWidth="3" />
      <path
        d="M27.625 35.9062C21.8095 35.9062 17.0781 40.6376 17.0781 46.4531C17.0781 52.2687 21.8095 57 27.625 57C33.4405 57 38.1719 52.2687 38.1719 46.4531C38.1719 40.6376 33.4405 35.9062 27.625 35.9062ZM32.2817 45.1723L26.813 50.6411C26.5842 50.8699 26.2843 50.9844 25.9844 50.9844C25.6845 50.9844 25.3845 50.8699 25.1558 50.6411L22.812 48.2973C22.3544 47.8398 22.3544 47.0977 22.812 46.6401C23.2696 46.1825 24.0116 46.1825 24.4693 46.6401L25.9844 48.1552L30.6245 43.5151C31.0821 43.0575 31.8241 43.0575 32.2818 43.5151C32.7394 43.9727 32.7394 44.7148 32.2817 45.1723Z"
        fill="currentColor"
      />
      <path
        d="M47.9375 27.1562C47.2903 27.1562 46.7656 26.6316 46.7656 25.9844V17H29.9688C28.0302 17 26.4531 18.5771 26.4531 20.5156V33.6163C26.8392 33.5813 27.2299 33.5625 27.625 33.5625C31.6194 33.5625 35.1951 35.3888 37.5614 38.25H50.2812C50.9284 38.25 51.4531 38.7747 51.4531 39.4219C51.4531 40.0691 50.9284 40.5938 50.2812 40.5938H39.1053C39.8378 42.0231 40.3102 43.6066 40.4618 45.2812H50.2812C50.9284 45.2812 51.4531 45.8059 51.4531 46.4531C51.4531 47.1003 50.9284 47.625 50.2812 47.625H40.4618C40.1113 51.4952 38.0435 54.877 35.028 57H53.4062C55.3448 57 56.9219 55.4229 56.9219 53.4844V27.1562H47.9375ZM50.2812 33.5625H33.0938C32.4466 33.5625 31.9219 33.0378 31.9219 32.3906C31.9219 31.7434 32.4466 31.2188 33.0938 31.2188H50.2812C50.9284 31.2188 51.4531 31.7434 51.4531 32.3906C51.4531 33.0378 50.9284 33.5625 50.2812 33.5625Z"
        fill="currentColor"
      />
      <path d="M49.1094 17.6863V24.8126H56.2352L49.1094 17.6863Z" fill="currentColor" />
    </svg>
  )
}
