import React from 'react'

export default function Mute({ size, ...rest }) {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M13.5417 0H11.4583C9.44777 0 7.81226 1.63547 7.81226 3.646V14.0621C7.81226 16.0725 9.44772 17.708 11.4583 17.708H13.5417C15.5522 17.708 17.1877 16.0726 17.1877 14.0621V3.646C17.1876 1.63547 15.5522 0 13.5417 0Z"
        fill="currentColor"
      />
      <path
        d="M18.7502 9.37456C18.4627 9.37456 18.2293 9.60792 18.2293 9.89543V15.104C18.2293 17.1145 16.5939 18.75 14.5833 18.75H10.4165C8.40602 18.75 6.7705 17.1145 6.7705 15.104V9.89538C6.7705 9.60787 6.53714 9.37451 6.24963 9.37451C5.96212 9.37451 5.72876 9.60787 5.72876 9.89538V15.1039C5.72876 17.6884 7.83197 19.7916 10.4165 19.7916H11.979V23.9585H8.33302C8.0455 23.9585 7.81215 24.1918 7.81215 24.4793C7.81215 24.7668 8.0455 25.0002 8.33302 25.0002H16.6667C16.9542 25.0002 17.1876 24.7668 17.1876 24.4793C17.1876 24.1918 16.9542 23.9585 16.6667 23.9585H13.0207V19.7916H14.5833C17.1678 19.7916 19.271 17.6884 19.271 15.1039V9.89538C19.2711 9.60787 19.0377 9.37456 18.7502 9.37456Z"
        fill="currentColor"
      />
    </svg>
  )
}
