import { useEffect } from 'react'
import { useLayout } from '../context'

export default function Loading({ children, loading }) {
  const { setLoading } = useLayout()

  useEffect(() => {
    setLoading(loading)
  }, [loading])

  return children
}
