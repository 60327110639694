import React from 'react'
import classnames from 'classnames'
import { Plural } from '@lingui/macro'

export default function Counter({ results, query }) {
  return (
    <div className="bg-white px-2 sm:px-4 h-10 border-t border-gray-200 flex items-center">
      <p
        className={classnames(
          'm-0 text-sm text-gray-600 transition duration-300',
          results && query ? 'opacity-100' : 'opacity-0'
        )}
      >
        <Plural value={results?.length || 0} one="# resultado" other="# resultados" />
      </p>
    </div>
  )
}
