import React from 'react'
import { motion, AnimateSharedLayout } from 'framer-motion'
import { Trans } from '@lingui/macro'

export default function SourceSelector({ sources, currentSource, onSelect }) {
  return (
    <AnimateSharedLayout>
      <div className="px-2 sm:px-4 py-2">
        <div className="flex justify-between items-center border p-1 rounded-full overflow-auto">
          <div className="relative">
            <button onClick={() => onSelect(null)} className="px-4 py-2 z-20 relative">
              <span className="text-xs sm:text-sm text-gray-700 leading-none block">
                <Trans>Todos</Trans>
              </span>
            </button>
            {!currentSource ? (
              <motion.div
                layoutId="sourceActiveIndicator"
                className="absolute top-0 left-0 w-full h-full rounded-full bg-gray-300 bg-opacity-50 z-10"
              />
            ) : null}
          </div>

          {sources.map((source) => (
            <div key={source.key} className="relative">
              <button onClick={() => onSelect(source.key)} className="px-4 py-2 z-20 relative">
                <span className="text-xs sm:text-sm text-gray-700 leading-none block">{source.name()}</span>
              </button>
              {source.key === currentSource ? (
                <motion.div
                  layoutId="sourceActiveIndicator"
                  className="absolute top-0 left-0 w-full h-full rounded-full bg-gray-300 bg-opacity-50 z-10"
                />
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </AnimateSharedLayout>
  )
}
