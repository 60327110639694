import React from 'react'

export default function SafetyGlasses({ size, ...rest }) {
  return (
    <svg width={size} height={size} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g>
        <path
          d="m62 33h-1.042c-.434-12.642-8.997-23.557-21.21-26.936l.25-4.002c.017-.275-.08-.546-.269-.747s-.453-.315-.729-.315h-14c-.276 0-.54.114-.729.315s-.286.472-.269.747l.25 4.002c-12.213 3.379-20.776 14.294-21.21 26.936h-1.042c-.552 0-1 .447-1 1v8c0 .553.448 1 1 1h3v13c0 3.859 3.14 7 7 7h15c.265 0 .52-.105.707-.293l4.293-4.293 4.293 4.293c.187.188.442.293.707.293h17c3.86 0 7-3.141 7-7v-13h1c.552 0 1-.447 1-1v-8c0-.553-.448-1-1-1zm-3.042 0h-20.894l1.556-24.886c11.13 3.266 18.911 13.285 19.338 24.886zm-21.022-30-1.875 30h-8.121l-1.876-30zm-13.556 5.114 1.556 24.886h-20.894c.427-11.601 8.208-21.62 19.338-24.886zm34.62 47.886c0 2.757-2.243 5-5 5h-16.586l-4.707-4.707c-.391-.391-1.023-.391-1.414 0l-4.707 4.707h-14.586c-2.757 0-5-2.243-5-5v-13h2v13c0 1.654 1.346 3 3 3h14c.265 0 .52-.105.707-.293l5.293-5.293 5.293 5.293c.187.188.442.293.707.293h16c1.654 0 3-1.346 3-3v-13h2zm-11.895-8.447-4.723 9.447h-2.764l7-14h8.382v13c0 .552-.449 1-1 1h-9.382l4.276-8.553zm-9.379 8.759-5.019-5.019c-.391-.391-1.023-.391-1.414 0l-5.707 5.707h-5.968l4.276-8.553-1.789-.895-4.723 9.448h-2.764l7-14h22.764zm-25.344.688h-.382c-.551 0-1-.448-1-1v-13h8.382zm48.618-16h-58v-6h58z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
