import React from 'react'

export default function Mountains({ size, ...rest }) {
  return (
    <svg width={size} height={size} viewBox="0 0 37 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M31.563 4.5531L23.1557 13.2417C22.9262 13.4767 22.6373 13.5914 22.346 13.5633C22.0546 13.5351 21.7819 13.3661 21.5818 13.0896L12.1913 0L0 20H37L31.563 4.5531ZM9.79388 10.7571L6.07309 16.2835C6.01724 16.3664 5.94937 16.4342 5.87336 16.483C5.79734 16.5318 5.71467 16.5607 5.63006 16.568C5.54544 16.5753 5.46055 16.5609 5.38023 16.5256C5.2999 16.4903 5.22572 16.4348 5.16191 16.3623C5.0981 16.2897 5.04591 16.2016 5.00833 16.1028C4.97075 16.0041 4.94851 15.8967 4.94288 15.7868C4.93725 15.6769 4.94834 15.5666 4.97552 15.4623C5.0027 15.3579 5.04543 15.2615 5.10128 15.1787L8.82181 9.65274C8.87737 9.56868 8.9452 9.49974 9.02138 9.44992C9.09757 9.40011 9.1806 9.37039 9.26568 9.36248C9.35076 9.35458 9.4362 9.36865 9.51707 9.40389C9.59793 9.43912 9.67263 9.49481 9.73683 9.56775C9.80103 9.64069 9.85348 9.72944 9.89113 9.82885C9.92879 9.92827 9.9509 10.0364 9.95621 10.147C9.96151 10.2576 9.9499 10.3684 9.92204 10.4731C9.89417 10.5778 9.85061 10.6744 9.79388 10.7571ZM12.0907 7.34273L11.3563 8.43583C11.3005 8.51883 11.2327 8.58675 11.1568 8.63572C11.0808 8.68468 10.9982 8.71373 10.9135 8.72121C10.7427 8.73631 10.5742 8.66262 10.4452 8.51636C10.3161 8.37009 10.2371 8.16322 10.2255 7.94127C10.2138 7.71932 10.2706 7.50045 10.3832 7.33283L11.1176 6.23972C11.1734 6.15673 11.2412 6.0888 11.3171 6.03984C11.3931 5.99087 11.4757 5.96182 11.5603 5.95435C11.6449 5.94687 11.7298 5.96111 11.8102 5.99626C11.8906 6.03141 11.9648 6.08678 12.0287 6.1592C12.0926 6.23163 12.1449 6.31969 12.1826 6.41836C12.2203 6.51703 12.2427 6.62439 12.2484 6.73429C12.2542 6.84419 12.2432 6.95448 12.2161 7.05888C12.1891 7.16328 12.1465 7.25973 12.0907 7.34273Z"
        fill="currentColor"
      />
    </svg>
  )
}
