import React from 'react'
import LoadingWrapper from '../../generic/LoadingWrapper'
import useSearchQuery from './hooks/useSearchQuery'
import SourceSelector from './components/SourceSelector'
import Messages from './components/Messages'
import Results from './components/Results'
import Counter from './components/Counter'

/**
 * Search by query by one or more sources.
 * @param query
 * @param {[{ key: string, name: string, service: class, renderItem: function }]} sources
 * @returns {JSX.Element}
 * @constructor
 */
export default function SearchResults({ query, sources }) {
  const { results, loading, currentSource, setCurrentSource } = useSearchQuery({ query, sources })

  return (
    <div className="relative">
      <LoadingWrapper loading={loading}>
        <div className="sticky top-0 bg-white z-30 border-b border-gray-200">
          <SourceSelector sources={sources} currentSource={currentSource} onSelect={setCurrentSource} />
          <Counter results={results} query={query} />
        </div>

        <Messages query={query} results={results} />
        <Results results={results} sources={sources} />
      </LoadingWrapper>
    </div>
  )
}
