import React, { useState } from 'react'
import SEO from '../SEO'
import Container from '../Container'
import Footer from '../Footer'
import classnames from 'classnames'
import NavBar from '../NavBar'
import Loading from './components/Loading'
import LoadingWrapper from '../LoadingWrapper'
import { LayoutContext } from './context'
import { useStore } from '../../../providers/store/context'
import useService from '../../../services/hooks/useService'
import CompaniesService from '../../../services/CompaniesService'
import useSWR from 'swr'
import { useRouter } from 'next/router'
import { useAuth } from '../../../providers/authentication/context'
import dynamic from 'next/dynamic'
import RequireAccessModal from './components/RequireAccessModal'
import InactiveMessage from '../../shared/InactiveMessage'

const MobileNavigation = dynamic(() => import('../NavBar/components/MobileNavigation'), { ssr: false })

export default function Layout({
  children,
  className,
  style,
  showNavBar = true,
  showMobileNavBar = true,
  showFooter = true,
  requireAccessIfAnonymous = true,
  SEOProps,
}) {
  const companiesService = useService(CompaniesService)
  const { data: tags } = useSWR(['institution', '/companies/tags?Type'], companiesService.findAllTagsByType)
  const { user, isUserValid } = useAuth()
  const { pathname } = useRouter()
  const { store } = useStore()
  const [isWebViewMode] = useState(store?.mode === 'webview')
  const [loading, setLoading] = useState(false)
  const [forceRequireAccess, setForceRequireAccess] = useState(false)

  return (
    <LayoutContext.Provider value={{ loading, setLoading, forceRequireAccess, setForceRequireAccess }}>
      <SEO {...SEOProps} />
      {!isWebViewMode && showNavBar ? <NavBar tags={tags} /> : null}
      {showMobileNavBar && showNavBar && user && (store.mode !== 'webview' || pathname === '/') ? (
        <MobileNavigation institutionTags={tags} />
      ) : null}
      <div className={isWebViewMode ? 'select-none pb-24' : ''}>
        <LoadingWrapper as="main" loading={loading} style={style} className={classnames(className || 'screen-height')}>
          {user && !user.IsActive ? <InactiveMessage /> : children}
        </LoadingWrapper>
      </div>
      {!isWebViewMode && showFooter ? <Footer /> : null}
      {(requireAccessIfAnonymous || forceRequireAccess) && !isUserValid ? <RequireAccessModal /> : null}
    </LayoutContext.Provider>
  )
}

Layout.Container = Container
Layout.SEO = SEO
Layout.Loading = Loading
