import useRequest from '../../../../services/hooks/useRequest'
import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { sortByKey } from '../../../../utils/sort'

export default function useSearchQuery({ query, sources }) {
  // When null, search from sources.
  const [currentSource, setCurrentSource] = useState(null)

  // Where the results will be stored after the request is made.
  const [results, setResults] = useState(null)

  // Execute actions from all source at once and then merge its results.
  const searchFromAllSources = async (query) => {
    const promises = sources.map((source) => {
      const service = new source.service()
      return service[source.action](query)
    })

    const results = await Promise.all(promises)

    for (let i = 0; i < sources.length; i++) {
      if (results[i]?.Items?.length > 0) {
        results[i].Items = results[i].Items.map((item) => ({ ...item, _Type: sources[i].key }))
      }
    }

    const count = results.reduce(
      (accumulator, current) => (current?.Items ? accumulator + current.Items.length : accumulator),
      0
    )

    const items = results.reduce(
      (accumulator, current) => (current?.Items ? [...accumulator, ...current.Items] : accumulator),
      []
    )

    return {
      Count: count,
      Items: sortByKey(items, 'Similarity', true),
    }
  }

  const searchFromSingleSource = (source) => async (query) => {
    const service = new source.service()
    const results = await service[source.action](query)

    if (results?.Items?.length > 0) {
      results.Items = results.Items.map((item) => ({ ...item, _Type: source.key }))
    }

    return results
  }

  // Depending on the current selected source, returns the corresponding request.
  const getRequest = () => (query, sourceKey) => {
    const source = sources.find((source) => source.key === sourceKey)
    if (source) {
      return searchFromSingleSource(source)(query)
    } else {
      return searchFromAllSources(query)
    }
  }

  const { exec, loading } = useRequest(getRequest())

  const handleSearch = useCallback(
    debounce(async (query, sourceKey) => {
      const result = await exec(query, sourceKey)
      setResults(result?.Items)
    }, 500),
    []
  )

  useEffect(() => {
    if (query) {
      handleSearch(query, currentSource)
    } else {
      setResults(null)
    }
  }, [query, currentSource])

  return { loading, results, currentSource, setCurrentSource }
}
