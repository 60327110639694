import React from 'react'

export default function Calendar2({ size, ...rest }) {
  return (
    <svg width={size} height={size} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M20.0352 1.79688H17.9688V0.71875C17.9688 0.32182 17.647 0 17.25 0C16.853 0 16.5312 0.32182 16.5312 0.71875V1.79688H6.46875V0.71875C6.46875 0.32182 6.14697 0 5.75 0C5.35303 0 5.03125 0.32182 5.03125 0.71875V1.79688H2.96484C1.33 1.79688 0 3.12688 0 4.76172V20.0352C0 21.67 1.33 23 2.96484 23H20.0352C21.67 23 23 21.67 23 20.0352V4.76172C23 3.12688 21.67 1.79688 20.0352 1.79688ZM2.96484 3.23438H5.03125V3.95312C5.03125 4.35005 5.35303 4.67188 5.75 4.67188C6.14697 4.67188 6.46875 4.35005 6.46875 3.95312V3.23438H16.5312V3.95312C16.5312 4.35005 16.853 4.67188 17.25 4.67188C17.647 4.67188 17.9688 4.35005 17.9688 3.95312V3.23438H20.0352C20.8774 3.23438 21.5625 3.91952 21.5625 4.76172V6.46875H1.4375V4.76172C1.4375 3.91952 2.12265 3.23438 2.96484 3.23438ZM20.0352 21.5625H2.96484C2.12265 21.5625 1.4375 20.8774 1.4375 20.0352V7.90625H21.5625V20.0352C21.5625 20.8774 20.8774 21.5625 20.0352 21.5625Z"
        fill="currentColor"
      />
    </svg>
  )
}
