import React from 'react'
import Container from '../../generic/Container'
import Card from '../../generic/Card'
import SmallTitle from '../../generic/SmallTitle'
import Link from 'next/link'
import { Trans } from '@lingui/macro'
import { useAuth } from '../../../providers/authentication/context'

const LinkWrapper = ({ children, href }) => (
  <Link href={href}>
    <a className="text-orange-500" target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  </Link>
)

export default function InactiveMessage() {
  const { user } = useAuth()
  return (
    <Container maxWidth={550}>
      <Card className="mt-10">
        <Card.Body>
          <SmallTitle>
            <Trans>Acceso limitado</Trans>
          </SmallTitle>
          <p className="text-gray-700 leading-tight">
            <Trans>
              <span>Tu cuenta ha sido bloqueada por incumplir con los</span>{' '}
              <LinkWrapper href="/static/documents/terms.pdf">términos de uso</LinkWrapper>
              <span>. Por favor, ponte en contacto con</span>{' '}
              <a className="text-orange-500" href="mailto:soporte@netmin.cl">
                soporte@netmin.cl
              </a>{' '}
              <span>para resolver la situación.</span>
            </Trans>
          </p>

          {user?.DeactivateReason ? (
            <div>
              <p className="text-gray-700 leading-none font-bold mt-5 mb-1">
                <Trans>Razón</Trans>:
              </p>
              <p className="p-3 bg-gray-200 bg-opacity-50 rounded-lg text-gray-700 leading-tight ">
                {user.DeactivateReason}
              </p>
            </div>
          ) : null}
        </Card.Body>
      </Card>
    </Container>
  )
}
