import React from 'react'

export default function Unnmute({ size, ...rest }) {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_5239_11491)">
        <path
          d="M11.4575 0.000488281H13.5409C15.5514 0.000488281 17.1869 1.63593 17.1869 3.64643V14.0623C17.1869 14.3873 17.1306 14.6967 17.0504 14.9967L7.81165 5.33812V3.64643C7.81165 1.63598 9.44709 0.000488281 11.4575 0.000488281Z"
          fill="currentColor"
        />
        <path
          d="M13.5409 17.7082H11.4575C9.44707 17.7082 7.81158 16.0727 7.81158 14.0622V8.35272L0.664576 0.880659C0.466671 0.672305 0.472921 0.343146 0.681227 0.144166C0.889582 -0.0548132 1.21874 -0.0464634 1.41772 0.160817L24.335 24.1198C24.5339 24.327 24.5266 24.6583 24.3183 24.8563C24.2173 24.9521 24.0881 25 23.9579 25C23.8204 25 23.6839 24.9459 23.5819 24.8396L17.6296 18.6165C16.8077 19.3332 15.7567 19.7916 14.5827 19.7916H13.0201V23.9583H16.6661C16.9536 23.9583 17.1869 24.1917 17.1869 24.4792C17.1869 24.7667 16.9536 25.0001 16.6661 25.0001H8.33249C8.04499 25.0001 7.81163 24.7667 7.81163 24.4792C7.81163 24.1917 8.04499 23.9583 8.33249 23.9583H11.9784V19.7915H10.4159C7.83146 19.7915 5.72828 17.6884 5.72828 15.1039V9.89548C5.72828 9.60798 5.96164 9.37462 6.24914 9.37462C6.53665 9.37462 6.77 9.60798 6.77 9.89548V15.1039C6.77 17.1144 8.40545 18.7499 10.4159 18.7499H14.5827C15.4775 18.7499 16.2806 18.4071 16.913 17.8676L15.8952 16.804C15.2597 17.3561 14.4482 17.7082 13.5409 17.7082Z"
          fill="currentColor"
        />
        <path
          d="M18.2286 15.1038V9.89538C18.2286 9.60787 18.462 9.37451 18.7495 9.37451C19.037 9.37451 19.2703 9.60787 19.2703 9.89538V15.1038C19.2703 15.7517 19.138 16.3684 18.8995 16.9299L18.0807 16.0736C18.1671 15.7632 18.2286 15.4424 18.2286 15.1038Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_5239_11491">
          <rect width="25" height="25" fill="white" transform="matrix(-1 0 0 1 25 0)" />
        </clipPath>
      </defs>
    </svg>
  )
}
