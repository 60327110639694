import React from 'react'
import DesktopItem from '../DesktopItem'
import Icon from '../../../Icon'
import UnreadNotificationsIndicator from '../../../UnreadNotificationsIndicator'
import { t, Trans } from '@lingui/macro'
import UnreadMessagesIndicator from '../../../UnreadMessagesIndicator'
import FloatingCard from '../../../FloatingCard'

export default function DesktopMenu() {
  return (
    <div className="flex h-full text-center">
      <DesktopItem
        exact
        href="/"
        label={t`Inicio`}
        accessory={() => <Icon name="house2" size={18} className="text-gray-700" />}
      />

      <DesktopItem
        exact
        href="/featured"
        label={t`Novedades`}
        accessory={() => <Icon name="novelty" size={18} className="text-gray-700" />}
      />

      <div className="relative h-full">
        <FloatingCard
          clickOutsideClassName={'h-full'}
          className={'h-full'}
          renderTrigger={(toggle) => (
            <div className="h-full" onClick={toggle}>
              <DesktopItem
                bottomArrow
                href="/industry"
                label={t`Organizaciones`}
                accessory={() => <Icon name="industry" size={10} className="text-gray-700 h-4 w-10" />}
                useLink={false}
                className={'h-full'}
                activeRoutes={['/industry', '/companies']}
              />
            </div>
          )}
        >
          <FloatingCard.Button
            renderCustomIcon={() => <Icon name="business" size={10} className="text-gray-700 h-4 w-10" />}
            linkProps={{ href: '/industry' }}
            asLink={true}
          >
            <Trans>Industria</Trans>
          </FloatingCard.Button>
          <FloatingCard.Button
            renderCustomIcon={() => <Icon name="safety-glasses" size={10} className="text-gray-700 h-4 w-10" />}
            linkProps={{ href: '/companies' }}
            asLink={true}
          >
            <Trans>Empresas</Trans>
          </FloatingCard.Button>
        </FloatingCard>
      </div>

      <div className="relative h-full">
        <FloatingCard
          clickOutsideClassName={'h-full'}
          className={'h-full'}
          renderTrigger={(toggle) => (
            <div className="h-full" onClick={toggle}>
              <DesktopItem
                bottomArrow
                href="/activities"
                useLink={false}
                className={'h-full'}
                label={t`Actividades`}
                activeRoutes={['/activities', '/networking']}
                accessory={() => <Icon name="calendar2" size={18} className="text-gray-700" />}
              />
            </div>
          )}
        >
          <FloatingCard.Button
            renderCustomIcon={() => <Icon name="calendar2" size={10} className="text-gray-700 h-4 w-10" />}
            linkProps={{ href: '/activities' }}
            asLink={true}
          >
            <Trans>Eventos</Trans>
          </FloatingCard.Button>
          <FloatingCard.Button
            renderCustomIcon={() => <Icon name="business" size={10} className="text-gray-700 h-4 w-10" />}
            linkProps={{ href: '/networking/conferences' }}
            asLink={true}
          >
            <Trans>Ruedas de Negocio</Trans>
          </FloatingCard.Button>
        </FloatingCard>
      </div>

      <DesktopItem
        href="/chat"
        label={t`Mensajes`}
        accessory={() => (
          <Icon name="chatCloud" size={18} className="text-gray-700" indicator={<UnreadMessagesIndicator />} />
        )}
      />

      <DesktopItem
        href="/notifications"
        label={t`Notificaciones`}
        accessory={() => (
          <Icon name="bell2" size={18} className="text-gray-700" indicator={<UnreadNotificationsIndicator />} />
        )}
      />
    </div>
  )
}
