import React, { useContext, useState, createContext } from 'react'
import { FiSearch } from 'react-icons/fi'
import Modal from '../Modal'
import Card from '../Card'
import { t, Trans } from '@lingui/macro'
import Icon from '../Icon'
import VerticalReveal from '../VerticalReveal'

const SearchContext = createContext({ close: () => null })
export const useSearchbar = () => useContext(SearchContext)

export default function SearchBar({ children }) {
  const [open, setOpen] = useState(false)
  const [query, setQuery] = useState('')

  return (
    <SearchContext.Provider value={{ close: () => setOpen(false) }}>
      <div>
        <button
          className="inline-flex w-full bg-gray-200 bg-opacity-75 overflow-hidden-z rounded"
          onClick={() => setOpen(true)}
        >
          <div className="inline-flex items-center pl-4 pr-4 sm:pr-12 xl:pr-24 py-1 md:py-2 space-x-3">
            <Icon name="magnifier" size={18} className="text-gray-600" />
            <span className="text-xs sm:text-sm font-semibold text-gray-600 leading-none block truncate">
              <Trans>Buscar...</Trans>
            </span>
          </div>
        </button>

        <Modal animate open={open} centered={false} onClose={() => setOpen(false)} maxWidth={600}>
          <Card className="my-10 overflow-hidden-z">
            <Card.Body className="p-2  sm:p-4 bg-gray-200">
              <div className="relative rounded-lg overflow-hidden shadow-minex transform translate-z-0">
                <div className="absolute top-0 bottom-0 left-0 flex items-center pointer-events-none">
                  <FiSearch className="mx-3 text-orange-500" />
                </div>
                <input
                  autoFocus
                  onChange={(e) => setQuery(e.target.value)}
                  type="text"
                  className="w-full pl-10 pr-5 py-3 focus:outline-none text-gray-800"
                  placeholder={t`Buscar...`}
                />
              </div>
            </Card.Body>
            <VerticalReveal duration={500} delay={0} open className="relative">
              <div className="max-h-lg overflow-auto relative">{children?.({ query, setQuery })}</div>
            </VerticalReveal>
          </Card>
        </Modal>
      </div>
    </SearchContext.Provider>
  )
}
