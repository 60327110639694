import React from 'react'
import Image from 'next/image'
import { BsImageFill } from 'react-icons/bs'
import { Trans } from '@lingui/macro'
import { FiChevronRight } from 'react-icons/fi'
import WebViewCompatibleLink from '../../generic/WebViewCompatibleLink'

export default function SearchResultItemGroup({ group }) {
  return (
    <WebViewCompatibleLink href={`/groups/${group.UUID}`} onClick={close} className="w-full block">
      <div className="w-full bg-white hover:bg-gray-100 p-3 overflow-hidden-z">
        <div className="flex items-center w-full space-x-3 box-border">
          <div>
            {group.Banner?.Thumbnail ? (
              <div className="rounded-full overflow-hidden-z w-12 sm:w-16 h-12 sm:h-16 bg-gray-200">
                <Image src={group.Banner.Thumbnail} layout="fill" objectFit="cover" />
              </div>
            ) : (
              <div className="flex items-center justify-center w-12 sm:w-16 h-12 sm:h-16 rounded-full bg-gray-200">
                <BsImageFill className="text-3xl opacity-75 text-gray-400" />
              </div>
            )}
          </div>
          <div className="flex-1 overflow-hidden-z space-y-1">
            <span className="block text-xs font-semibold text-orange-800 group-hover:text-white leading-none uppercase m-0">
              <Trans>Grupo</Trans>
            </span>
            <h4 className="block text-gray-800 font-semibold group-hover:text-white leading-none w-full text-sm sm:text-base truncate">
              {group.Name.es}
            </h4>
            <p className="block text-sm font-medium leading-tight text-gray-700 group-hover:text-white truncate">
              Administrador: {group.Included?.Admin?.Name}
            </p>
          </div>
          <div>
            <FiChevronRight />
          </div>
        </div>
      </div>
    </WebViewCompatibleLink>
  )
}
