import React from 'react'
import Container from '../Container'
import Link from 'next/link'
import { Trans } from '@lingui/macro'

export default function Footer() {
  return (
    <footer className="w-full pb-16 lg:pb-0 ">
      <div className="bottom-safe-area-padding">
        <Container>
          <div className="flex flex-col sm:flex-row items-center justify-center space-x-0 sm:space-x-4 py-2 text-gray-500 text-center">
            <a
              href="https://forms.gle/J7UYpmqE1P4eURBJ9"
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm font-semibold px-3 py-2 hover:bg-gray-300 hover:bg-opacity-25 rounded-lg"
            >
              <span>
                <Trans>Ingresa tu organización</Trans>
              </span>
            </a>

            <Link href="/static/documents/terms.pdf">
              <a
                className="text-sm font-semibold px-3 py-2 hover:bg-gray-300 hover:bg-opacity-25 rounded-lg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>
                  <Trans>Términos de Uso</Trans>
                </span>
              </a>
            </Link>

            <Link href="/pages/privacy">
              <a className="text-sm font-semibold px-3 py-2 hover:bg-gray-300 hover:bg-opacity-25 rounded-lg">
                <span>
                  <Trans>Privacidad</Trans>
                </span>
              </a>
            </Link>
          </div>
        </Container>
      </div>
    </footer>
  )
}
