import Service from './Service'

export default class NotificationsService extends Service {
  findAllNotificationsByRecipientUUID = async (recipientUUID) => {
    return await this._get(
      `/notifications?RecipientUUID=${recipientUUID}&include=Subject&order=-CreatedAt&offset=0&limit=30`
    )
  }

  findReadNotificationsCountByRecipientUUID = async (recipientUUID) => {
    return await this._get(`/notifications?RecipientUUID=${recipientUUID}&Read=false&offset=0&limit=0`)
  }

  readNotificationsByUUIDsAndRecipientUUID = async (uuids, RecipientUUID) => {
    return await this._post('/notifications/read', { NotificationUUIDs: uuids, RecipientUUID: RecipientUUID })
  }

  readAllNotificationsByRecipientUUID = async (RecipientUUID) => {
    return await this._post('/notifications/read-all', { RecipientUUID: RecipientUUID })
  }
}
