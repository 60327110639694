import React, { useState } from 'react'
import FloatingCard from '../../../FloatingCard'
import Avatar from '../../../Avatar'
import { useAuth } from '../../../../../providers/authentication/context'
import Link from 'next/link'
import { panelURL } from '../../../../../config'
import { Trans } from '@lingui/macro'
import HelpModal from '../../../../shared/HelpModal'
import clsx from 'clsx'

const Trigger = ({ onClick, fullName, image, textClassname }) => (
  <button className="w-full p-2 rounded-md hover:bg-gray-200 hover:bg-opacity-50" onClick={onClick}>
    <div className="flex md:flex-col items-center justify-center space-x-2 md:space-x-0 md:space-y-1">
      <Avatar text={fullName} image={image} className="w-6 h-6 text-white text-xs" />
      <span className={clsx(' hidden sm:block text-xs xl:text-sm font-semibold leading-none', textClassname)}>
        <Trans>Mi Perfil</Trans>
      </span>
    </div>
  </button>
)

export default function AccountOptions({ textClassname = 'text-gray-700' }) {
  const [helpOpen, setHelpOpen] = useState(false)
  const { user, signOut } = useAuth()
  const fullName = `${user?.FirstName} ${user?.LastName}`
  const company = user?.Companies.length > 0 ? user.Companies[0] : null

  return (
    <div className="h-full relative inline-flex items-center">
      <FloatingCard
        renderTrigger={(onToggle) => (
          <Trigger onClick={onToggle} fullName={fullName} image={user.Photo?.Thumbnail} textClassname={textClassname} />
        )}
      >
        <FloatingCard.Content>
          <div className="flex items-center">
            <Avatar text={fullName} image={user.Photo?.Thumbnail} className="w-10 h-10 text-white" />
            <div className="ml-2">
              <span className="text-gray-800 font-semibold leading-none">{fullName}</span>
              <Link href="/account">
                <a className="block text-xs leading-none text-orange-500">
                  <Trans>Editar cuenta</Trans>
                </a>
              </Link>
            </div>
          </div>
        </FloatingCard.Content>
        <FloatingCard.Divider />
        <FloatingCard.Button asLink linkProps={{ href: `/profile/u/${user.UUID}` }} icon="User">
          <Trans>Ir a mi perfil</Trans>
        </FloatingCard.Button>
        {company ? (
          <React.Fragment>
            <FloatingCard.Button asLink linkProps={{ href: `/profile/c/${company.UUID}` }} icon="Monitor">
              <Trans>Ir a mi empresa</Trans>
            </FloatingCard.Button>
            <FloatingCard.Button
              icon="Settings"
              asComponent="a"
              href={panelURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans>Ir a panel de administración</Trans>
            </FloatingCard.Button>
          </React.Fragment>
        ) : null}
        <FloatingCard.Divider />
        <FloatingCard.Button icon="LogOut" onClick={signOut}>
          <Trans>Cerrar sesión</Trans>
        </FloatingCard.Button>
        <FloatingCard.Button icon="HelpCircle" onClick={() => setHelpOpen(true)}>
          <Trans>Ayuda</Trans>
        </FloatingCard.Button>
      </FloatingCard>

      <HelpModal open={helpOpen} onClose={() => setHelpOpen(false)} />
    </div>
  )
}
